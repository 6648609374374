<template>
  <View>
    <template v-slot:view-content>
      <div>
        <div class="container rounded bg-white mt-5 mb-5">
          <div class="row">
            <div class="col-md-3 border-right">
              <div class="d-flex flex-column align-items-center text-center p-3 py-5">
                <div v-if="isLoading()"></div>
                <Loading v-if="isLoading()"></Loading>
                <img
                    class="img img-fluid rounded-circle mt-5"
                    :src="getProfileImage()"
                    v-else
                    @click="file.click()"
                    :alt="user.name"
                >
                <form enctype="multipart/form-data" novalidate>
                  <input
                      type="file"
                      ref="file"
                      style="display: none"
                      name="profile_image"
                      @change="uploadProfileImage($event.target.name, $event.target.files); state.fileCount = $event.target.files.length"
                  >
                </form>
                <LoadingField class="profile-loading" v-if="isLoading()"></LoadingField>
                <span class="font-weight-bold" v-else>{{ user.name }}</span>
                <LoadingField class="profile-loading" v-if="isLoading()"></LoadingField>
                <span class="text-black-50" v-else>{{ user.email }}</span>
                <LoadingField class="profile-loading" v-if="isLoading()"></LoadingField>
                <span class="text-black-50" v-else>
                  <div class="alert alert-secondary small p-2" role="alert" v-if="user.ldap_user">
  {{ $t('personalInfo.ldapInfo') }}
</div>
                </span>
              </div>
            </div>
            <div class="col-md-5 border-right">
              <span class="text-black-50" v-if="state.languageUpdated.value">
                  <div class="alert alert-warning small p-2" role="alert">{{
                      $t('personalInfo.languageChangeHint')
                    }}</div>
                </span>
              <div class="p-3 py-5">
                <span class="text-black-50">
                  <div class="alert alert-danger small p-2" role="alert" v-for="error in state.errors.value">{{
                      error
                    }}</div>
                </span>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h4 class="text-right">{{ $t('personalInfo.title') }}</h4>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="labels pb-1">{{ $t('personalInfo.firstName') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.title')"
                        v-model="user.first_name"
                        v-else
                        @blur="updateUser"
                    >
                  </div>
                  <div class="col-md-6">
                    <label class="labels pb-1">{{ $t('personalInfo.lastName') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.lastName')"
                        v-model="user.last_name"
                        v-else
                        @blur="updateUser"
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12 pt-3">
                    <label class="labels pb-1">{{ $t('personalInfo.email') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.title')"
                        v-model="user.email"
                        v-else
                        @blur="updateUser"
                    >
                  </div>
                  <div class="col-md-12 pt-3">
                    <label class="labels pb-1">{{ $t('personalInfo.phone') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.phone')"
                        v-model="user.phone"
                        v-else
                        @blur="updateUser"
                    >
                  </div>
                  <div class="col-md-12 pt-3">
                    <label class="labels pb-1">{{ $t('personalInfo.website') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.website')"
                        v-model="user.website"
                        v-else
                        @blur="updateUser"
                    >
                  </div>
                  <div class="col-md-12 pt-3">
                    <label class="labels pb-1">{{ $t('personalInfo.created') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.created')"
                        v-model="user.create_ts.date"
                        v-else
                        @blur="updateUser"
                    >
                  </div>
                  <div class="col-md-12 pt-3">
                    <label class="labels pb-1">{{ $t('personalInfo.locale') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>

                    <select
                        class="form-control form-select"
                        :aria-label="$t('personalInfo.locale')"
                        v-model="user.locale"
                        v-else
                        @change="updateUser"
                    >
                      <option v-for="(locale, key) in this.state.locales.value" :value="key"
                              :selected="key === user.language + '_' + user.locale">
                        <div v-if="key !== ''">
                          {{ locale }} ({{ key }})
                        </div>
                        <div v-else>{{ locale }}</div>
                      </option>
                    </select>

                  </div>

                  <div class="col-md-12 pt-3">
                    <label class="labels pb-1">{{ $t('personalInfo.hash') }}</label>
                    <LoadingField class="profile-loading-long" v-if="isLoading()"></LoadingField>
                    <input
                        type="text"
                        class="form-control font-weight-light"
                        :placeholder="$t('personalInfo.hash')"
                        v-model="user.hash"
                        v-else
                        disabled
                    >
                  </div>
                </div>
                <div v-if="state.configuration.value.passwordChangeEnabled">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <h4 class="text-right">{{ $t('personalInfo.passwordChange.title') }}</h4>
                  </div>
                  <div class="row mt-3">
                                  <span class="text-black-50">
                  <div class="alert alert-danger small p-2" role="alert"
                       v-for="error in state.updatePassword.value.errors">{{
                      error
                    }}</div>
                </span>
                    <span class="text-black-50">
                  <div class="alert alert-success small p-2" role="alert"
                       v-for="success in state.updatePassword.value.success">{{
                      success
                    }}</div>
                </span>
                    <div class="col-md-12 pt-3">
                      <label class="labels pb-1">{{ $t('personalInfo.passwordChange.oldPassword.title') }}</label>
                      <LoadingField class="profile-loading-long" v-if="isUpdatePasswordLoading()"></LoadingField>
                      <input
                          :type="state.updatePassword.value.values.oldPassword.focused ? 'text' : 'password'"
                          class="form-control font-weight-light"
                          :placeholder="$t('personalInfo.passwordChange.oldPassword.placeholder')"
                          v-model="state.updatePassword.value.values.oldPassword.value"
                          @focusin="state.updatePassword.value.values.oldPassword.focused = !state.updatePassword.value.values.oldPassword.focused"
                          @focusout="state.updatePassword.value.values.oldPassword.focused = !state.updatePassword.value.values.oldPassword.focused"
                          v-else
                      >
                    </div>
                    <div class="col-md-12 pt-3">
                      <label class="labels pb-1">{{ $t('personalInfo.passwordChange.newPassword.title') }}</label>
                      <LoadingField class="profile-loading-long" v-if="isUpdatePasswordLoading()"></LoadingField>
                      <input
                          :type="state.updatePassword.value.values.newPassword.focused ? 'text' : 'password'"
                          class="form-control font-weight-light"
                          :placeholder="$t('personalInfo.passwordChange.newPassword.placeholder')"
                          v-model="state.updatePassword.value.values.newPassword.value"
                          @focusin="state.updatePassword.value.values.newPassword.focused = !state.updatePassword.value.values.newPassword.focused"
                          @focusout="state.updatePassword.value.values.newPassword.focused = !state.updatePassword.value.values.newPassword.focused"
                          v-else
                      >
                    </div>
                    <div class="col-md-12 pt-3">
                      <label class="labels pb-1">{{ $t('personalInfo.passwordChange.newPasswordRepeat.title') }}</label>
                      <LoadingField class="profile-loading-long" v-if="isUpdatePasswordLoading()"></LoadingField>
                      <input
                          :type="state.updatePassword.value.values.newPasswordRepeat.focused ? 'text' : 'password'"
                          class="form-control font-weight-light"
                          :placeholder="$t('personalInfo.passwordChange.newPasswordRepeat.placeholder')"
                          @focusin="state.updatePassword.value.values.newPasswordRepeat.focused = !state.updatePassword.value.values.newPasswordRepeat.focused"
                          @focusout="state.updatePassword.value.values.newPasswordRepeat.focused = !state.updatePassword.value.values.newPasswordRepeat.focused"
                          v-model="state.updatePassword.value.values.newPasswordRepeat.value"
                          v-else
                      >
                    </div>
                    <div class="col-md-12 pt-3">
                      <LoadingField class="profile-loading-long" v-if="isUpdatePasswordLoading()"></LoadingField>
                      <button class="btn btn-primary btn-sm buttonBase" @click="onChangePassword">
                        {{ $t('personalInfo.passwordChange.updateButton') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
  </View>
</template>

<script>
import View from "@/components/Common/View";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {ROUTES} from "@/src/Config/PersonalInfo/routes";
import {ROUTES as COMMONROUTES} from "@/src/Config/Common/routes";
import {useStore} from "vuex";
import Loading from "@/components/Common/Loading";
import _ from "lodash";
import LoadingField from "@/components/Common/LoadingField";
import {API_SERVICE, APP_STORAGE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";

export default {
  name: "PersonalInfo",
  components: {LoadingField, Loading, View},
  setup() {
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    /** @var {AppStorage} appStorage */
    const appStorage = container.get(APP_STORAGE);

    const route = useRoute();
    const i18n = useI18n();
    const store = useStore();
    const file = ref(null);
    const state = reactive(
        {
          loading: true,
          axios: null,
          appStorage: null,
          fileCount: 0,
          locales: {
            "": "",
            "en_US": "English",
            "de_DE": "German",
          },
          languageUpdated: false,
          configuration: {
            uploadMaxFilesize: 0,
            passwordChangeEnabled: true
          },
          errors: [],
          updatePassword: {
            loading: true,
            errors: [],
            values: {
              oldPassword: {
                value: null,
                focused: false
              },
              newPassword: {
                value: null,
                focused: false
              },
              newPasswordRepeat: {
                value: null,
                focused: false
              }
            }
          },
        }
    );

    function getProfileImage() {
      const user = store.state.user;
      return COMMONROUTES.getAssetUrl(user.jwt)
    }

    const user = computed(
        {
          get() {
            return store.state.user;
          },
          set(user) {
            store.commit('setUser', user);
          }
        }
    );

    function showErrors(errors) {
      state.errors = errors;
      setTimeout(
          () => {
            state.errors = [];
          },
          6000
      )
    }

    function showUpdatePasswordErrors(errors) {
      state.updatePassword.errors = errors;
      setTimeout(
          () => {
            state.updatePassword.errors = [];
          },
          6000
      )
    }

    function showUpdatePasswordSuccess(success) {
      state.updatePassword.success = success;
      setTimeout(
          () => {
            state.updatePassword.success = [];
          },
          6000
      )
    }

    function bytesForHuman(bytes) {
      const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
      let i = 0
      for (i; bytes > 1024; i++) {
        bytes /= 1024;
      }
      return parseFloat(bytes.toFixed(2)) + ' ' + units[i]
    }

    function uploadProfileImage(fieldName, fileList) {
      state.loading = true;
      let formData = new FormData();
      if (!fileList.length) {
        state.loading = false;
        return;
      }

      const filesTooBig = [];
      Array
          .from(Array(fileList.length).keys())
          .map(x => {
            if (fileList[x].size > state.configuration.uploadMaxFilesize) {
              filesTooBig.push(
                  {
                    name: fileList[x].name,
                    size: fileList[x].size,
                  }
              );
              return;
            }
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

      if (filesTooBig.length > 0) {
        const messageArray = [];
        for (let i = 0; i < filesTooBig.length; i++) {
          const file = filesTooBig[i];
          messageArray.push(
              'files size of ' + file.name + ' (' + bytesForHuman(file.size) + ') exceeds allowed maximum of ' + bytesForHuman(state.configuration.uploadMaxFilesize)
          )
        }
        showErrors(messageArray);
        state.loading = false;
        return;
      }

      formData.append(
          'user_hash', store.state.user.hash
      )

      _.debounce(
          () => {
            apiService.post(
                ROUTES.getUpdateProfileImage()
                , formData
            )
                .then(
                    (response) => {
                      state.loading = false;
                      store.commit('setUserJwt', response.data.jwt);
                      file.value = null;

                      document.dispatchEvent(
                          new CustomEvent(
                              "listenToProfileImageChange"
                              , {
                                detail:
                                    {jwt: getProfileImage()}
                              }
                          )
                      )
                    }
                )
                .catch(
                    (r) => {
                      console.error(r);
                      file.value = null;
                      state.loading = false;
                    }
                )
          }, 500
      )();
    }

    function updateUser() {
      state.loading = true;
      _.debounce(
          () => {
            apiService.post(
                ROUTES.getUpdateUser()
                , {
                  user: this.user
                }
            )
                .then(
                    (response) => {
                      state.loading = false;
                      store.commit('setUser', response.data.user);

                      if (response.data.languageUpdated) {
                        appStorage.storePrimaryLanguage(response.data.user.locale.toLowerCase());
                        i18n.locale = appStorage.getPrimaryLanguage();
                        state.languageUpdated = true;
                      }
                    }
                )
                .catch(
                    (error) => {
                      if (error.response.status === 304) {
                        state.loading = false;
                      }
                    }
                )
          }, 500
      )();
    }

    onMounted(
        () => {
          getConfiguration();
        }
    )

    function isLoading() {
      return state.loading === true;
    }

    function isUpdatePasswordLoading() {
      return state.updatePassword.loading === true;
    }

    function onChangePassword(e) {
      e.preventDefault();
      if (
          state.updatePassword.values.oldPassword.value === ""
          || state.updatePassword.values.newPassword.value === ""
          || state.updatePassword.values.newPasswordRepeat.value === ""
      ) {
        showUpdatePasswordErrors(['please input values']);
        return;
      }

      if (state.updatePassword.values.newPassword !== state.updatePassword.values.newPasswordRepeat) {
        showUpdatePasswordErrors(['passwords do not match']);
        return;
      }

      apiService.post(
          ROUTES.updatePassword()
          , {
            userId: store.state.user.id,
            oldPassword: state.updatePassword.values.oldPassword.value,
            password: state.updatePassword.values.newPassword.value
          }
      ).then(
          () => {
            showUpdatePasswordSuccess(['password updated!']);
          }
      )
    }

    function getConfiguration() {
      apiService.get(
          ROUTES.getProfileConfiguration()
      ).then(
          (response) => {
            state.configuration = {...state.configuration, ...response.data};
            state.loading = false;
            state.updatePassword.loading = false;
          }
      )
    }

    return {
      state: toRefs(state),
      getProfileImage: getProfileImage,
      user: user,
      uploadProfileImage: uploadProfileImage,
      updateUser: updateUser,
      isLoading: isLoading,
      isUpdatePasswordLoading: isUpdatePasswordLoading,
      file: file,
      onChangePassword: onChangePassword
    }
  }
}
</script>

<style scoped>
.profile-loading-square {
  height: 150px;
  width: 150px;
}

.profile-loading {
  height: 10px;
  width: 150px;
}

.profile-loading-long {
  height: 25px;
  width: 150px;
}
</style>