/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";

const getDefaultState = () => {
    return {
        i18n: {},
        user: {}
    };
};

const globalStore = {
    strict: false,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        getI18n: state => {
            return state.i18n;
        },
        getUser: state => {
            return state.user;
        },
    },
    mutations: {
        SET_I18N: (state, i18n) => {
            state.i18n = i18n;
        },
        setUser: (state, user) => {
            state.user = user;
        },
        setUserJwt: (state, jwt) => {
            state.user.jwt = jwt;
        },
        clear: (state) => {
            state.user = {};
            state.i18n = {};
        }
    },
    actions: {
        setI18n: ({commit, dispatch}, {i18n}) => {
            commit('SET_I18N', i18n);
        }
    }
};

export default createStore(globalStore);
