<template>
  <div id="top-navigation"
       class="mt-3 container-fluid d-flex flex-row justify-content-between text-center align-items-center pt-2 pb-2">
    <div class="row d-flex flex-grow-1 justify-content-start">
      <div class="col justify-content-start d-flex gap-2 flex-column flex-lg-row flex-xl-row flex-xxl-row">
        <div v-if="!!!this.$slots.topNavigationContent()">{{ $t('topNavigation.slogan') }}</div>
        <slot name="topNavigationContent" v-else></slot>
      </div>
    </div>
    <div class="row align-items-center justify-content-center text-center">
      <div class="col d-flex flex-row justify-content-center text-center align-items-center">
        <div v-if="getEnv() === 1" class="bg-danger text-white p-1 rounded-1 form-label m-0">Development</div>
        <div v-if="getEnv() === 2" class="bg-warning text-white p-1 rounded-1 form-label m-0">Test</div>
      </div>
      <div
          class="col d-flex flex-row justify-content-center text-center align-items-center d-none d-lg-flex d-xl-flex d-xxl-flex">
        <div class="form-group" v-if="state.search.enabled">
          <input
              type="text"
              class="form-control-sm"
              id="search-input"
              aria-describedby="search"
              :placeholder="$t('topNavigation.input.placeholder')"
              @input.prevent="modifyStore"
              v-model="state.search.value"
          >

          <div class="np-result-container border rounded" ref="searchContainer">
            <div>
              <div v-if="state.search.state === 1 || state.search.state === 2">
                <div class="np-ib np-text-container np-result-item border-1 p-2">
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
              </div>

              <div
                  v-else
                  v-for="(result, resultIndex) in state.search.result"
                  :key="resultIndex"
                  @click="onResultClick(
                      {
                      id: result.id,
                      name: result.name,
                      type: result.type,
                      username: result.username,
                      url: result.url
                      }
                      )"
              >
                <div class="np-ib np-text-container np-result-item border-1 p-2" @click="showCharacterDetails(result)">
                  <div>
                    {{ result.name }}
                  </div>
                  <div class="np-result-description">
                    {{ result.description }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="col d-flex flex-row justify-content-center text-center align-items-center">
        <div id="user-box">
          <div class="p-2 d-flex flex-row justify-content-center align-items-center">
            <img
                :src="state.imagePath"
                class="rounded me-2" id="user-pic" :alt="user.name">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref} from 'vue';
import {ROUTES as COMMONROUTES} from "@/src/Config/Common/routes";
import _ from "lodash";
import {API_SERVICE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Register/routes";
import {onClickOutside} from "@vueuse/core";

export default {
  name: "TopNavigation",
  props: {
    hasSearch: false
  },
  setup(props) {
    const searchContainer = ref(null);
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);

    const store = useStore();
    const state = reactive(
        {
          search: {
            enabled: props.hasSearch,
            value: null,
            state: 0, // 0 = initial, 1 = searching, 2 = no results, 3 = results
            result: []
          },
          imagePath: null
        }
    );
    const user = computed(
        () => {
          return store.state.user;
        }
    )

    function showCharacterDetails(result) {
      this.characterDetails = result;
    }

    function modifyStore() {
      _.debounce(
          () => {
            state.search.state = 1;
            apiService.get(
                ROUTES.search(state.search.value)
            ).then(
                (r) => {
                  state.search.result = r.data.result;
                  state.search.state =
                      state.search.result.length > 0
                          ? 3
                          : 2
                  ;
                }
            )
          },
          10
      )();
    }

    onMounted(
        () => {
          state.imagePath = COMMONROUTES.getAssetUrl(store.state.user.jwt);

          document.addEventListener(
              'listenToProfileImageChange',
              (event) => {
                state.imagePath = event.detail.jwt;
              }
          );
        }
    )

    function getEnv() {
      if (process.env.NODE_ENV === 'development') {
        return 1;
      } else if (process.env.NODE_ENV === 'test') {
        return 2;
      }
      return 0;
    }

    onClickOutside(searchContainer,
        () => {
          closeSearchModal()
        }
    )

    function closeSearchModal() {
      state.search.result = [];
      state.search.value = null;
      state.search.state = 0;
    }

    function onResultClick(node) {
      document.dispatchEvent(
          new CustomEvent(
              "onResultItemClick"
              , {
                detail:
                    {node: node}
              }
          )
      );
      closeSearchModal();
    }

    return {
      user: user,
      state: state,
      modifyStore: modifyStore,
      getEnv: getEnv,
      showCharacterDetails: showCharacterDetails,
      searchContainer: searchContainer,
      onResultClick: onResultClick
    }
  }
}
</script>

<style scoped lang="scss">
$blurEffect: 0.5px;
#top-navigation {
  border-radius: 0.7rem;
  background-color: #F8F9FA;
  min-height: 2.5rem;
  box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#user-pic {
  width: 3rem;
}

.np-title {
  margin-left: 20px;
  margin-top: 30px;
  font-size: 18px;
  color: rgb(0, 64, 255);
}

.auto-search-container {
  background: #ffffff;
  width: 300px;
  margin-top: 30px;
  z-index: 9999;
  font-size: 24px;
  height: 44px;
  padding: 0px 14px;
}

.np-result-container {
  position: absolute;
  text-align: left;
}

.np-result-item {
  background-color: #FFFFFF;
  width: 320px;
  //margin-bottom: 12px;
  //padding: 12px 14px;
  cursor: pointer;
  //transition: all 0.3s;
}

.np-result-item:hover {
  background-color: #f2f2f2;

  transition: all 0.3s;
}

.np-result-description {
  font-size: 11px;
}

.np-ib {
  display: inline-block;
}

.np-avatar {
  height: 50px;
  border-radius: 12px;
}

.np-text-container {
  width: 180px;
  vertical-align: top;
  padding-left: 20px;
}

.np-details {
  vertical-align: top;
  border: 1px solid #eee;
  border-radius: 12px;
  margin-bottom: 12px;
  margin-left: 20px;
  margin-top: 30px;
  padding: 12px 14px;
  height: 400px;
}

.np-result-details-img {
  border-radius: 12px;
  height: 250px;
}

.np-result-details-title {
  font-size: 20px;
  padding: 8px 0px;
  font-weight: 500;
}

.np-result-details-description {
  font-size: 16px;
}
</style>
