<template>
  <div class="container-fluid p-0 m-0 d-flex flex-grow-1 justify-content-between">
    <div class="row flex-grow-1">
      <div class="col-2">
        <nav
            class="navbar navbar-expand-lg navbar-dark bg-primary fixed-left flex-column h-100 d-flex justify-content-start"
            id="left-navbar">
          <div class="container-fluid d-flex flex-row flex-lg-column flex-xl-column flex-xxl-column mt-3">
            <a class="d-flex gap-2 align-self-center"
               href="#">
              <img src="https://keestash.com/wp-content/uploads/2022/03/keestash-transparent-1.png" class="img-fluid"
                   alt="Responsive image" id="logo">
            </a>
            <p class="d-none d-lg-flex d-xl-flex d-xxl-flex text-white text-decoration-none">{{ $t('appName') }}</p>
          </div>
        </nav>
      </div>
      <div class="col-10">
        <div class="container mt-3">
          <div class="row">
            <h2>Public Share</h2>
          </div>
          <div class="row justify-content-between" v-if="state.state.value===4">
            <div class="alert alert-warning modal-input mt-3" style="font-weight: 500; font-size: 14px">Invalid link.
              Please generate a new one
            </div>
          </div>
          <div class="row justify-content-between" v-if="state.state.value===6">
            <div class="alert alert-danger modal-input mt-3" style="font-weight: 500; font-size: 14px">Incorrect
              Password
            </div>
          </div>
          <div class="row justify-content-between mt-3">
            <Loading v-if="state.state.value===1"></Loading>
          </div>
          <div class="row justify-content-between" v-if="state.state.value === 0 || state.state.value === 6">
            <div class="col">
              <input type="text" id="password-for-load" class="k-form-control-less-bold-500 modal-input"
                     placeholder="Enter Password"
                     v-model="state.password.value"
                     @focusout="onPasswordEnter"
              >
            </div>
          </div>
          <div class="row justify-content-between mt-3" v-if="state.state.value === 0|| state.state.value === 6">
            <div class="col">
              <button class="btn btn-primary" @click="onPasswordEnter">Load</button>
            </div>
          </div>
          <div class="row justify-content-between" v-if="state.state.value===5">
            <div class="col mb-4">
              <label for="username" class="modal-label mb-1">
                <small>{{ $t("password.modal.password.update.form.username.title") }}</small>
              </label>
              <div class="input-group mb-3">
                <input type="text" id="username" class="k-form-control-less-bold-500 modal-input"
                       placeholder="the placeholder"
                       v-model="state.decrypted.value.userName"
                >
                <button class="btn btn-light button-border input-group-text clickable" id="copy"
                        @click.prevent="onCopyUsernameClick">
                  <img src="./../../assets/img/common/files.svg" class="icon-image"
                       alt="add password"
                  >
                </button>

              </div>
            </div>
          </div>
          <div class="row justify-content-between" v-if="state.state.value===5">
            <div class="col">
              <PasswordField
                  title="The title"
                  placeholder="The placeholder"
                  @eyeClick="onEyeClick"
                  @copyClick="onCopyClick"
                  @onPasswordChange="()=>{}"
                  v-model="state.decrypted.value.password"
              ></PasswordField>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {reactive, toRefs} from "vue";
import View from "@/components/Common/View.vue";
import LeftNavigation from "@/components/Common/LeftNavigation.vue";
import {useRoute} from "vue-router";
import {API_SERVICE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Password/routes";
import Loading from "@/components/Common/Loading.vue";
import PasswordField from "@/components/Common/PasswordField.vue";

export default {
  name: "PublicShare",
  components: {PasswordField, Loading, LeftNavigation, View},
  setup() {

    // 0 initial
    // 1 loading
    // 2 no hash found in params
    // 3 api error
    // 4 no share found/expired
    // 5 loaded
    // 6 password incorrect

    const state = reactive(
        {
          state: 0,
          password: null,
          decrypted: {
            userName: null,
            password: null
          }
        }
    )

    function onCopyClick() {
      setTimeout(
          async () => {
            navigator.clipboard.writeText(state.decrypted.password)
                .then(
                    function () {
                    }
                )
                .catch(
                    function (e) {
                    }
                )
          }
      )
    }

    function onCopyUsernameClick() {
      setTimeout(
          async () => {
            navigator.clipboard.writeText(state.decrypted.userName)
                .then(
                    function () {
                    }
                )
                .catch(
                    function (e) {
                    }
                )
          }
      )
    }

    function onEyeClick(c) {
      c.visible = true;
    }

    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);

    const route = useRoute();
    const hash = route.params.hash || null;

    if (hash === null) {
      state.state = 2;
    }

    state.state = 0;

    function handle404(response) {
      if (response.data.responseCode === 7893576) {
        state.state = 4;
        state.password = '';
      }
      if (response.data.responseCode === 34561235) {
        state.state = 6;
        state.password = '';
      }
    }

    function onPasswordEnter() {
      if (state.state === 2) {
        return;
      }
      state.state = 1;
      apiService.post(
          ROUTES.getPublicShareData(hash),
          {
            password: state.password
          }
      ).then(
          (r) => {
            state.state = 5;
            state.decrypted = r.data.decrypted;
          }
      ).catch(
          (d) => {

            if (d.response.status === 404) {
              return handle404(d.response);
            }

            state.state = 3;

          }
      )
    }

    return {
      state: toRefs(state),
      onEyeClick: onEyeClick,
      onCopyClick: onCopyClick,
      onCopyUsernameClick: onCopyUsernameClick,
      onPasswordEnter: onPasswordEnter
    }
  }
}
</script>

<style scoped>

#left-navbar {
  min-width: 200px;
  max-width: 200px;
}

#logo {
  width: 70px;
}

.icon-image {
  width: 20px;
  color: white;
}

.button-border {
  border: 1px solid #ced4da;
}


</style>
