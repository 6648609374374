/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import json from './../../Common/Env/index';

export const ROUTES = {
    getIconUrl: (fileId, jwt) => {
        return json.asset.path + '/?token=' + jwt;
    },
    getDownloadLink: (fileId, jwt) => {
        return json.backend.path + '/password_manager/attachments/download/' + fileId + '/' + jwt;
    },
    getNode: (id) => {
        return json.backend.path + "/password_manager/node/get/" + id;
    },
    createPassword: () => {
        return json.backend.path + '/password_manager/node/credential/create';
    },
    updateCredential: () => {
        return json.backend.path + '/password_manager/credential/update';
    },
    createFolder: () => {
        return json.backend.path + '/password_manager/node/folder/create';
    },
    move: () => {
        return json.backend.path + '/password_manager/node/move';
    },
    removeNode: () => {
        return json.backend.path + '/password_manager/node/delete';
    },
    updateNode: () => {
        return json.backend.path + '/password_manager/node/folder/update';
    },
    getCredential(credentialId) {
        return json.backend.path + "/password_manager/node/credential/password/get/" + credentialId;
    },
    updatePassword() {
        return json.backend.path + '/password_manager/credential/password/update/';
    },
    getAdditionalData(id) {
        return json.backend.path + '/password_manager/credential/additional_data/get/' + id;
    },
    getAdditionalDataValue(id) {
        return json.backend.path + '/password_manager/credential/additional_data/get/value/' + id;
    },
    addAdditionalData() {
        return json.backend.path + '/password_manager/credential/additional_data/add';
    },
    getPublicShareData(hash) {
        return json.backend.path + '/password_manager/public_share/decrypt/'+hash;
    },
    getPublicShareUrl(hash) {
        return json.frontend.path + '/password/s/' + hash;
    },
    addAttachment() {
        return json.backend.path + '/password_manager/attachments/add';
    },
    removeAttachment() {
        return json.backend.path + '/password_manager/attachments/remove';
    },
    getAttachmentsByNodeId(nodeId) {
        return json.backend.path + '/password_manager/attachments/get/' + nodeId;
    },
    getPasswordManagerActivities(referenceKey, appId) {
        return json.backend.path + '/password_manager/credential/additional_data/get/' + referenceKey + '/' + appId;
    },
    deleteAdditionalData(id) {
        return json.backend.path + '/password_manager/credential/additional_data/delete/' + id;
    },
    getEntropy(value) {
        return json.backend.path + "/password_manager/generate/quality/" + value
    },
    getGeneratePassword(
        length,
        upperCase,
        lowerCase,
        digit,
        specialChars
    ) {
        return json.backend.path + '/password_manager/generate_password/' + length + '/' + upperCase + '/' + lowerCase + '/' + digit + '/' + specialChars;
    },
    publicShareSingle() {
        return json.backend.path + '/password_manager/share/public';
    },
    publicShareSinglePassword() {
        return json.backend.path + '/password_manager/share/public/password';
    }
}
