<template>
  <div>
    <label for="name" class="modal-label mb-1">
      <small>{{ title }}</small>
    </label>
    <div class="input-group">
      <input :type="state.passwordField.visible ? 'text' : 'password'"
             :class="state.passwordField.visible ?  'form-control' : 'k-form-control-less-bold-500'"
             class="modal-input"
             :readonly="!state.passwordField.visible"
             :placeholder="placeholder"
             :value="modelValue"
             @input="$emit('update:modelValue', $event.target.value);$emit('onPasswordChange',state.passwordField)"
      >
      <div class="input-group-append d-flex">
        <button class="btn btn-light button-border input-group-text flex-grow-1 clickable" id="copyField"
                @click.prevent="this.$emit('copyClick')">
          <img src="./../../assets/img/common/files.svg" class="icon-image"
               alt="add password"
          >
        </button>
        <button class="btn btn-light button-border input-group-text flex-grow-1 clickable" id="eye"
                @click.prevent="this.$emit('eyeClick',state.passwordField)">
          <img src="./../../assets/img/common/eye-fill.svg" class="icon-image"
               alt="add password"
               v-if="!state.passwordField.visible"
          >
          <img src="./../../assets/img/common/eye-slash-fill.svg" class="icon-image"
               alt="add password"
               v-else
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";

export default {
  name: "PasswordField",
  props: ["modelValue", "title", "placeholder"],
  setup() {
    const state = reactive(
        {
          passwordField: {
            visible: false,
          },
          input: ''
        }
    );

    function onInput() {

    }

    return {
      state: state,
      onInput: onInput
    };
  }
}
</script>

<style scoped>
#copyField {
  border-radius: 0;
}

#eye {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.modal-input {
  padding: 0.7rem;
}

.icon-image {
  height: 1.2rem;
}

.button-border {
  border: 1px solid #ced4da;
}

.modal-label {
  font-weight: 400;
  font-size: 1rem;
}
</style>
