/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import axios from 'axios'
import store from '../../Config/Store/index';
import _ from "lodash";

export const HEADER_NAME_USER = "x-keestash-user";
export const HEADER_NAME_TOKEN = "x-keestash-token";

export class ApiService {

    constructor(appStorage) {
        this.appStorage = appStorage;
    }

    get(url, data = {}, abortController = null) {
        const config = {
            params: data
            , headers: this.getCustomHeaders()
        };

        if (null !== abortController) {
            config.signal = abortController.signal
        }

        return axios.get(
            url
            , config
        ).catch(
            (response) => {
                let header = (response.response.headers['x-keestash-authentication'] || null);

                if (!(_.isString(header))) {
                    header = null;
                }

                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    post(url, data = {}, headers = {}) {

        return axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                ...headers,
                ...this.getCustomHeaders()
            }
        }).catch(
            (response) => {
                const header = response.response.headers['x-keestash-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    put(url, data = {}) {

        return axios({
            method: 'put',
            url: url,
            data: data,
            headers: this.getCustomHeaders()
        }).catch(
            (response) => {
                const header = response.response.headers['x-keestash-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    delete(url, data = {}) {
        return axios({
            method: 'delete',
            url: url,
            data: data,
            headers: this.getCustomHeaders()
        }).catch(
            (response) => {
                const header = response.response.headers['x-keestash-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    getCustomHeaders() {
        return {
            [HEADER_NAME_USER]: this.appStorage.getUserHash()
            , [HEADER_NAME_TOKEN]: this.appStorage.getToken()
        };
    }

}
