/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {AppStorage} from "@/src/Service/Storage/AppStorage";
import {ApiService} from "@/src/Service/Api/ApiService";
import {Container} from "@/src/Container/Container";
import {MetricService} from "@/src/Service/Metric/MetricService";

export const APP_STORAGE = "appstorage.storage";
export const API_SERVICE = "service.api";
export const METRIC_SERVICE = "service.metric";

export class ContainerFactory {

    constructor() {
        this.container = new Container();
    }

    create() {

        this.container.register(
            APP_STORAGE
            , () => {
                return new AppStorage();
            }
        );

        this.container.register(
            API_SERVICE
            , (container) => {
                return new ApiService(
                    container.get(APP_STORAGE)
                );
            }
        );
        this.container.register(
            METRIC_SERVICE
            , (container) => {
                return new MetricService(
                    container.get(API_SERVICE)
                );
            }
        );

        return this.container;
    }
}
